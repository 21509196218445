<template>
<div class="m-2">
  <div class="form-inline sub-mr-2">
    <div>Replay:</div>
    <video ref="replayVideo" playsinline autoplay muted controls/>
  </div>
  <hr/>
  <h2>Playback</h2>
  <div class="form-inline sub-mr-2 sub-mt-2">
    <div>stream.json URL:</div>
    <b-input type="text" placeholder="url" v-model="streamJsonURL"/>
    <b-button @click="play">Play</b-button>
  </div>
  <hr/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('msb-player');
import { fetchBlob } from '@/services/utils';
//import { storage } from "@/services/db";

export default {
  data() {
    return {
      streamJsonURL: "https://storage.cloud.google.com/hh-streams/recordings/rec-2023-02-13-1738/stream.json?_ga=2.9157302.-2015566746.1655836385",
      streamJson: null,

      gsRoot: 'gs://hh-streams/tests-msb',
      gsHttpRoot: 'https://firebasestorage.googleapis.com/v0/b/hh-streams/o',

      stream: null,
      buffers: [],
      replayStream: null,
      remoteFolder: null,
      mediaRecorder: null,
      isRecording: false,
      manifest: null,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
    },
    async loadJson() {
      let blob = await fetchBlob(this.streamJsonURL);
      log.log("JSON", blob);
      this.streamJson = JSON.parse(blob.text);
    },
    async play() {
      this.loadJson();
    },
    replayRemote() {
      this.replayCount = 0;
      let that = this;
    
      let ms = new MediaSource();
      log.log("MediaSource", ms);
      let mimeType = this.mimeType;
      this.$refs.replayVideo.src = URL.createObjectURL(ms);

      ms.addEventListener('sourceopen', function () {
        log.log("MediaSource onsourceopen", mimeType, "typeSupported=", MediaSource.isTypeSupported(mimeType));
        let sb = ms.addSourceBuffer(mimeType);

        async function addNextBuffer() {
          let path = "";
          if (that.replayRemoteURL) {
            //https://streamozoa.ngrok.io/tests-msb/mobilechrome/stream0.mp4
            path = `${that.replayRemoteURL}/stream${that.replayCount}.mp4`;
          } else {
            //https://firebasestorage.googleapis.com/v0/b/hh-streams/o/tests-msb%2Fchrome%2Fstream0.msb?alt=media
            let file = encodeURIComponent(`tests-msb/${that.remoteFolder}/stream${that.replayCount}.msb`);
            path = `${that.gsHttpRoot}/${file}?alt=media`;
          }
          try {
            log.log("addNextBuffer", path);
            let b = await fetchBlob(path);
            log.log("fetchBlob", b);
            let ab = await b.arrayBuffer();
            log.log("arrayBuffer", ab, sb);
            sb.appendBuffer(ab);
            that.replayCount += 1;
          } catch (e) {
            log.log('addNextBuffer error', e);
          }
        }

        sb.onerror = function(ev) {
          log.log("SB error=", ev);
        };
        sb.onupdateend = addNextBuffer;
        addNextBuffer();
      });
    }
  }
}
</script>

<style>

</style>